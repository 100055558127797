import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { PLReferral } from '@root/src/app/common/interfaces';
import {
  PLGraphQLService,
  PLHttpService,
  PLUrlsService,
} from '@root/src/lib-components';

@Component({
  selector: 'app-referral-bulk-action-modal',
  templateUrl: './referral-bulk-action-modal.component.html',
  styleUrls: ['./referral-bulk-action-modal.component.less'],
})
export class ReferralBulkActionModalComponent implements OnInit {
  referralControl = new FormControl('', Validators.required);
  reasons = [];
  noHoldReferrals = [];
  holdReferrals = 0;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public modalInput: { referrals: PLReferral[]; title: string },
    private plHttpService: PLHttpService,
    private plUrlsService: PLUrlsService,
    private plGraphQLService: PLGraphQLService,
  ) {
    this.plHttpService
      .get(
        '',
        {},
        this.plUrlsService.urls.referralsStatusReasons + '?query=ON_HOLD',
      )
      .subscribe(res => {
        this.reasons = res.reasons;
      });
  }

  ngOnInit(): void {
    this.noHoldReferrals = this.modalInput.referrals.filter(
      referral =>
        referral.state === 'DELETED' ||
        referral.state === 'CONVERTED' ||
        referral.isRsmSchool,
    );

    this.holdReferrals = this.modalInput.referrals.filter(
      referral =>
        !this.noHoldReferrals.some(
          noHoldReferral => noHoldReferral.id === referral.id,
        ),
    ).length;
  }

  onNoClick(): void {
    this.dialog.closeAll();
  }

  submitBulkAction() {
    const referralsToOnHold = this.modalInput.referrals.filter(
      referral =>
        !this.noHoldReferrals.some(
          noHoldReferral => noHoldReferral.id === referral.id,
        ),
    );
    const variables = {
      ids: referralsToOnHold.map(referral => referral.id),
      reason: this.referralControl.value,
    };
    this.plGraphQLService
      .query(GQL_MUTATE_REFERRAL_HOLD, variables)
      .pipe(first())
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }
}

const GQL_MUTATE_REFERRAL_HOLD = `
  mutation onHoldReferrals($ids: [ID!]!, $reason: String!) {
    onHoldReferrals(input: {ids: $ids, reason: $reason}) {
      errors {
        code
      }
    }
  }
`;
