import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { of, Observable } from 'rxjs';
import {
  concatMap,
  withLatestFrom,
  catchError,
  map,
  tap,
  switchMap,
  filter,
} from 'rxjs/operators';
import { AppStore } from '@app/appstore.model';
import { fetchNextTimesheet } from '@common/billing/store/next-timesheet/next-timesheet.actions';
import { PLUtilService } from '@common/services';
import { selectCurrentUser } from '@common/store/user.selectors';
import { PLTimezoneService, PLToastService } from '@root/index';
import * as eventActions from './schedule.actions';
import * as eventSelectors from './schedule.selectors';
import {
  PL_EVENT_SOURCE,
  PLGetAppointmentsParams,
  PLEvent,
} from '../../models';
import { PLScheduleService, PLAppointmentService } from '../../services';
import * as documentationActions from '../documentation/documentation.actions';

@Injectable({ providedIn: 'root' })
export class ScheduleEffects {
  private user$ = this.store$.select(selectCurrentUser);
  private userId$ = this.user$.pipe(map(user => user.uuid));

  getEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eventActions.PLGetEvents),
        withLatestFrom(this.userId$, this.route.queryParams),
        tap(([action, user, queryParams]) => {
          const { vs, ve } = queryParams;
          let { start, end } = queryParams;
          if (vs && ve) {
            start = vs;
            end = ve;
          }
          if (action.start) {
            start = action.start;
          }
          if (action.end) {
            end = action.end;
          }
          const { source, provider = user, timezone } = action;
          this.store$.dispatch(
            eventActions.PLLoadEvents({
              payload: { source, start, end, provider, timezone },
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventActions.PLLoadEvents),
      withLatestFrom(this.user$),
      concatMap(([{ payload }, user]) => {
        let eventType = 'BILLING';
        if (payload.source === PL_EVENT_SOURCE.Availability) {
          eventType = 'AVAILABILITY';
        }
        const _end = dayjs(payload.end).add(1, 'days');
        const _start = dayjs(payload.start).subtract(1, 'days');
        return this.loadEvents(payload.provider, _start, _end, eventType).pipe(
          map(({ results: events }) => {
            const { start, end, timezone } = payload;
            const _events: PLEvent[] = events.map(e => {
              const _timezone =
                timezone || (user.xProvider ? user.xProvider.timezone : '');
              return this.toLocalTime(e, _timezone);
            });
            return eventActions.PLLoadEventsSuccess({
              payload: { start, end, events: _events },
            });
          }),
          catchError(err =>
            of(eventActions.PLLoadEventsFail({ payload: err })),
          ),
        );
      }),
    ),
  );

  loadAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventActions.PLLoadAppointment),
      withLatestFrom(this.user$),
      concatMap(([{ payload: uuid }, user]) => {
        const queryParams: PLGetAppointmentsParams = {
          uuid,
          calendar_view: true,
        };
        return this.scheduleService.getAppointments(queryParams).pipe(
          map((payload: PLEvent) => {
            const _timezone = user.xProvider ? user.xProvider.timezone : '';
            return eventActions.PLSetAppointment({
              appointment: this.toLocalTime(payload, _timezone),
            });
          }),
          catchError(err => of(eventActions.PLSchedulerError({ error: err }))),
        );
      }),
    ),
  );

  scheduleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eventActions.PLSchedulerError),
        filter(({ error }) => !!error),
        tap(({ error }) => this.plToast.show('error', error)),
      ),
    { dispatch: false },
  );

  scheduleUpdated$ = this.actions$.pipe(
    ofType(
      eventActions.PLSetAppointment,
      eventActions.PLSaveEventSuccess,
      eventActions.PLRemoveEvent,
      eventActions.PLRemoveRepeatingEvent,
    ),
  );

  fetchDocumentationAssistant$ = createEffect(() =>
    this.scheduleUpdated$.pipe(
      map(() => documentationActions.PLFetchDocumentationAssistant({})),
    ),
  );

  fetchNextTimesheet$ = createEffect(() =>
    this.scheduleUpdated$.pipe(map(() => fetchNextTimesheet())),
  );

  deleteEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventActions.PLDeleteEvent),
      switchMap(({ event, deleteType, isAmendable }) =>
        this.appointmentService
          .delete(
            {
              event,
              reason: isAmendable ? 'Correction' : '',
            },
            deleteType,
          )
          .pipe(
            map(({ uuid }) => {
              let following;
              const _uuid = this.getUuid(event);
              if (deleteType === 'following') {
                following = event.original_start;
              }
              return deleteType !== 'one'
                ? eventActions.PLRemoveRepeatingEvent({ uuid, following })
                : eventActions.PLRemoveEvent({ uuid: _uuid });
            }),
            catchError(({ error }) => {
              let errorMessage;
              if (error && error.non_field_errors) {
                errorMessage = error.non_field_errors.join('\n');
              }
              return of(eventActions.PLSchedulerError({ error: errorMessage }));
            }),
          ),
      ),
    ),
  );

  loadEvaluations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventActions.PLLoadEvaluations),
      withLatestFrom(this.userId$),
      concatMap(([actions, user]) => {
        const { statusIn: status__in, assignedTo: assigned_to = user } =
          actions.payload;
        return this.scheduleService
          .getPendingClients({ assigned_to, status__in })
          .pipe(
            map(({ results: payload }) =>
              eventActions.PLLoadEvaluationsSuccess({ payload }),
            ),
            catchError(err =>
              of(eventActions.PLLoadEvaluationsFail({ payload: err })),
            ),
          );
      }),
    ),
  );

  goToCalendar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eventActions.PLGoToCalendar),
        withLatestFrom(
          this.userId$,
          this.store$.select(eventSelectors.selectScheduleView),
        ),
        tap(([_, __, view]) => {
          this.router.navigate([], {
            queryParams: {
              view: view.type,
              start: dayjs(view.date).format('YYYY-MM-DD'),
            },
          });
        }),
      ),
    { dispatch: false },
  );

  loadEvents(
    provider: string,
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    event_type__in = 'BILLING',
  ): Observable<{ results: PLEvent[] }> {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    const queryParams: PLGetAppointmentsParams = {
      provider,
      event_type__in,
      calendar_view: true,
      start: `${start.format(format)}`,
      end: `${end.format(format)}`,
    };
    return this.scheduleService.getAppointments(queryParams);
  }

  private toLocalTime(
    appointment: PLEvent,
    timezone: string,
    forDisplay: boolean = true,
  ): PLEvent {
    const { apptStart, apptEnd, apptOriginalEnd, apptOriginalStart } =
      this.plUtil.computeAppointmentLocalDateTimes(
        appointment,
        timezone,
        forDisplay,
      );
    return {
      ...appointment,
      end: apptEnd.format(this.plTimezone.formatDateTime),
      start: apptStart.format(this.plTimezone.formatDateTime),
      original_start: apptOriginalStart.format(this.plTimezone.formatDateTime),
      original_end: apptOriginalEnd.format(this.plTimezone.formatDateTime),
    };
  }

  private getUuid(item: PLEvent): string {
    const { event, original_start } = item;
    let { uuid } = item;
    if (!uuid) {
      uuid = `evt__${event.uuid}${
        event.repeating ? `__${original_start}` : ''
      }`;
    }
    return uuid;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actions$: Actions,
    private store$: Store<AppStore>,
    private scheduleService: PLScheduleService,
    private appointmentService: PLAppointmentService,
    private plTimezone: PLTimezoneService,
    private plUtil: PLUtilService,
    private plToast: PLToastService,
  ) {}
}
